// import WorkerURL from '../utils/workers/productWorker.js?url';
// const productWorker = new Worker(WorkerURL);

import { productSort } from "./product";

function getToken(){
    return document.querySelector('meta[name="_token"]').getAttribute('content');
}

const paths = { 
    json: '/resources/assets/json/',
    product: '/resources/assets/product/',
    lang: '/resources/assets/lang/'
}
// @ts-ignore
const lang = document.body.getAttribute('data-lang');

/**
 * @param {string} url
 * @param {string | string[][] | Record<string, string> | URLSearchParams} [data]
 */
function get(url, data){
    return new Promise((resolve, reject) => {
        if(data) url += new URLSearchParams(data);
        fetch(url).then(response=>{
            if(response.ok){
                response.json().then(data=>{
                    resolve(data);
                });
            }else{
                responseError(response, url);
                reject({});
            }
        });
    });
}

/**
 * @param {string} url
 * @param {object} data
 */
function send(url, data){
    return new Promise((resolve, reject) => {
        fetch(url, {
            method: 'POST',
            headers:{
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'X-CSRF-Token': getToken()
            },
            body: JSON.stringify(data)
        }).then(response=>{
            if(response.ok){
                response.json().then(data=>{
                    resolve(data);
                });
            }else{
                responseError(response, url);
                reject(response);
            }
        });
    });
}

/**
 * @param {string} type
 */
async function getLanguage(type){
    let data;
    try{
        data = await get('/resources/assets/lang/'+ lang + '/' + type + '.json');
    }catch(error){
        data = await get('/resources/assets/lang/en/' + type + '.json');
    }
    return data;
} 

/**
 * @param {Response} data
 * @param {string} url
 */
function responseError(data, url){
    const message = `Невозможно отправить/принять данные.\nStatus: ${data.status}\nURL: ${url}`;
    new Error(message);
    console.error(message)
    return message;
}


/**
 * @param {string} productName
 * @param {undefined} [filterValue]
 */
function getSeries(productName, filterValue){
    return new Promise((resolve, reject) => {
        // productWorker.onmessage = ev=>{
        //     console.log(ev.data);
        //     resolve(ev.data);
        // }
        // productWorker.onmessageerror = ev=>{
        //     console.log(ev.data);
        //     reject(ev.data);
        // }
        get('/resources/assets/products/' + productName + '/product_' + lang + '.json').then(json=>{
            const filter = json.filter;
            // productWorker.postMessage([
            //     json,
            //     filter
            // ]);
            if(filter){
                filter.value = filterValue || filter.defaultValue;
            }
            if(json.type != 'el-sig_variative'){
                productSort(json.variants, 'name');
            }
            resolve(json);
            return json;
        }).catch(()=>{
            // reject({});
        });
    });
}


const localeRoute = (function(){
    if(lang == 'en'){
        return '';
    }else{
        return '/' + lang;
    }
})()

const url = (function(){
    let path = window.location.pathname;
    if(path[path.length - 1] == '/'){
        path = path.slice(0, -1);
    }
    // console.log(path);
    const prev = path.slice(0, path.lastIndexOf('/'));
    return {
        "path": document.getElementsByName('path')[0].getAttribute('content'),
        "previous": prev,
        "previousName": prev.slice(prev.lastIndexOf('/') + 1),
        "slug": path.slice(path.lastIndexOf('/') + 1),
        "hash": window.location.hash,
        "withoutLang": path.replace('/'+lang, ''),
        "full": window.location.href
    }
})()

/**
 * @param {boolean} force
 */
function toggleOverflowPage(force = undefined){
    document.querySelector('body').toggleAttribute('data-noscroll', force);
    document.querySelector('html').toggleAttribute('data-noscroll', force);
}

export {get, send, getLanguage, paths, getSeries, localeRoute, lang, url, toggleOverflowPage};